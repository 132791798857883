import {
  useState, useEffect, useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import DownloadExcelButton from "components/DownloadExcelButton";
import SkeletonLoader from "components/SkeletonLoader";
import {
  handleMessages, HTTP_STATUS_RESPONSE, MESSAGE_TYPES, toast,
} from "components/Toast/functions";
import { SessionContext } from "modules/session/context";
import {
  ALIGN_ITEMS, COLORS_NAME, COMPONENT, SKELETONS_NUMBER,
} from "common/constants";
import {
  isAdmin as isAdminFunction,
  isAdminOrManager as isAdminOrManagerFunction,
  isManager as isManagerFunction,
} from "common/utils";
import {
  resetStateProcess,
  getAgreementsToDownload as getAgreementList,
} from "redux/actions/agreements/agreementActions";
import {
  getList as getAgreementCategoriesList,
  resetStateProcess as resetStateProcessAgreementCategory,
} from "redux/actions/agreements/agreementCategoriesActions";
import { getList as getStatesList } from "redux/actions/agreements/agreementStatesActions";
import EmployeeModal from "views/OrganizationChart/components/EmployeeModal";
import FeedbackFormModal from "views/Account/components/Feedback/components/FeedbackFormModal";
import AcknowledgmentFormModal from "views/Account/components/Feedback/components/AcknowledgmentFormModal";
import CollapseAgreementsTable from "../CollapseAgreementsTable";
import { downloadExcel, getHeader, getRowsDesktop } from "../../functions";
import { StyledContainerManagerData, StyledSwitch } from "./styles";

const InProgress = (props) => {
  const {
    setSelectId,
    employeeId,
    agreementsList,
    isLoadingAgreementsList,
    agreementsListTotal,
    handlePagination,
    pagination,
    handleSearch,
    managerData,
    agreementsTotal,
    showTeam,
    isValidToPrivateAgreements,
    isValidToSuccessionPlan,
    filterName,
  } = props;
  const { t } = useTranslation(["developmentPlan", "tables"]);
  const dispatch = useDispatch();

  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const [isOpenAcknowledgement, setIsOpenAcknowledgement] = useState(false);
  const [isOpenMain, setIsOpenMain] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState();

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdminOrManager = isAdminOrManagerFunction(user?.userCookies);
  const isManagerAndAdmin = isManagerFunction(user?.userCookies)
    && isAdminFunction(user?.userCookies);

  const handleOpenFeedback = () => {
    setIsOpenMain(false);
    setIsOpenFeedback(true);
  };
  const handleOpenAcknowledgement = () => {
    setIsOpenMain(false);
    setIsOpenAcknowledgement(true);
  };

  const onClickNode = (employee) => {
    setEmployeeSelected(employee);
    setIsOpenMain(true);
  };

  const {
    successProcessAgreement,
    errorList,
    generalList: generalAgreementList,
    isLoadingGeneralList: isLoadingGeneralAgreements,
  } = useSelector(({ agreementsReducer }) => agreementsReducer);

  const {
    successProcessAgreementCategory,
  } = useSelector(({ agreementsCategoryReducer }) => agreementsCategoryReducer);

  const {
    list: statesList,
  } = useSelector(({ agreementsStatesReducer }) => agreementsStatesReducer);

  const isAdmin = isAdminFunction(user?.userCookies);

  useEffect(() => {
    dispatch(getStatesList());
    dispatch(getAgreementCategoriesList());
    dispatch(getAgreementList());
    // eslint-disable-next-line
  }, [dispatch]);

  // TODO: Create Feedback component
  const showAlertErrorOrSuccess = (isError) => {
    const toastMessage = {
      message: t(`feedback:${isError ? "error_feedback" : "send_feedback_successfully"}`),
      title: t(`common:common.api_responses.${isError ? "error.title" : "success.title"}`),
    };
    toast(MESSAGE_TYPES.success, toastMessage, t);
  };

  const getCollapseData = (item) => (
    <CollapseAgreementsTable
      data={ item }
      onClickNode={ onClickNode }
      employeeId={ employeeId }
      isValidToPrivateAgreements={ isValidToPrivateAgreements }
      isValidToSuccessionPlan={ isValidToSuccessionPlan }
    />
  );

  useEffect(() => {
    if (successProcessAgreement || successProcessAgreementCategory) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      if (successProcessAgreement) {
        dispatch(resetStateProcess());
      } else {
        dispatch(getAgreementCategoriesList());
        dispatch(resetStateProcessAgreementCategory());
      }
    }
  }, [successProcessAgreement, successProcessAgreementCategory, dispatch, t]);

  return (
    <>
      {managerData
        && (
          <StyledContainerManagerData>
            <TablePaginationCollapse
              header={ getHeader(t) }
              list={ managerData }
              getRows={ {
                mobile: getRowsDesktop,
                desktop: getRowsDesktop,
              } }
              modalOptions={ [setSelectId, getCollapseData, employeeId] }
            />
          </StyledContainerManagerData>
        )}
      {isManagerAndAdmin && (
        <div>
          <FormControl component={ COMPONENT.fieldset }>
            <FormGroup>
              <FormControlLabel
                control={ (
                  <StyledSwitch
                    color={ COLORS_NAME.primary }
                    checked={ showTeam.active }
                    onChange={ showTeam.handleChange }
                  />
                ) }
                label={ t("tables:actions.view_team") }
              />
            </FormGroup>
          </FormControl>
        </div>
      )}
      { agreementsTotal === null && isAdminOrManager
        && <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />}
      { agreementsTotal > 0
      && (
        <TablePaginationCollapse
          header={ getHeader(t) }
          list={ agreementsList }
          isLoading={ isLoadingAgreementsList || statesList?.length === 0 }
          error={ errorList }
          getRows={ {
            mobile: getRowsDesktop,
            desktop: getRowsDesktop,
          } }
          extraParam={ user }
          handlePagination={ handlePagination }
          pagination={ pagination }
          total={ agreementsListTotal }
          handleSearch={ isAdminOrManager ? handleSearch : "" }
          rightOptions={ isAdmin && (
            <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              <Box flexGrow={ 1 }>
                <DownloadExcelButton
                  isDisabled={ isLoadingGeneralAgreements }
                  onClick={ downloadExcel(t, generalAgreementList, statesList) }
                />
              </Box>
            </Box>
          ) }
          modalOptions={ [setSelectId, getCollapseData, employeeId] }
          hiddenHeader={ managerData }
          defaultSearchValue={ filterName }
        />
      )}
      <EmployeeModal
        employee={ employeeSelected }
        isOpen={ isOpenMain }
        onOpenFeedback={ () => handleOpenFeedback() }
        onOpenAcknowledgement={ () => handleOpenAcknowledgement() }
        onClose={ () => setIsOpenMain(false) }
      />
      <FeedbackFormModal
        employeeId={ employeeSelected }
        isOpen={ isOpenFeedback }
        onClose={ () => setIsOpenFeedback(false) }
        onSend={ showAlertErrorOrSuccess }
      />
      <AcknowledgmentFormModal
        employeeId={ employeeSelected }
        isOpen={ isOpenAcknowledgement }
        onClose={ () => setIsOpenAcknowledgement(false) }
        onSend={ showAlertErrorOrSuccess }
      />
    </>
  );
};

InProgress.propTypes = {
  setSelectId: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  agreementsList: PropTypes.array,
  isLoadingAgreementsList: PropTypes.bool,
  agreementsListTotal: PropTypes.array,
  handlePagination: PropTypes.func,
  pagination: PropTypes.number,
  handleSearch: PropTypes.func,
  managerData: PropTypes.array,
  agreementsTotal: PropTypes.number,
  showTeam: PropTypes.shape({
    active: PropTypes.bool,
    handleChange: PropTypes.func,
  }),
  isValidToPrivateAgreements: PropTypes.bool,
  isValidToSuccessionPlan: PropTypes.bool,
  filterName: PropTypes.string,
};

InProgress.defaultProps = {
  agreementsList: [],
  isLoadingAgreementsList: false,
  agreementsListTotal: [],
  handlePagination: () => {},
  pagination: 1,
  handleSearch: "",
  managerData: null,
  agreementsTotal: 0,
  showTeam: null,
  isValidToPrivateAgreements: false,
  isValidToSuccessionPlan: false,
  filterName: null,
};

export default InProgress;
