import i18next from "i18next";
import parse from "html-react-parser";
import { differenceInDays } from "date-fns";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import FileSaver from "file-saver";
import moment from "moment";
import crypto from "crypto";
import CryptoJS from "crypto-js";
import XLSX from "xlsx";
import { Workbook } from "exceljs";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import lodashRound from "lodash/round";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import find from "lodash/find";
import divide from "lodash/divide";
import gte from "lodash/gte";
import some from "lodash/some";
import charts from "theme/charts";
import palette from "theme/palette";
import {
  UNITS,
  CONTRACT_TYPES,
  CITIES,
  POSITIONS,
  ADDITIONAL_FIELDS,
} from "views/GeneralAdministrator/functions";
import { getAvatarImage } from "components/MultipleAnswers/functions";
import { getCountry } from "views/GeneralAdministrator/functions/cities";
import { MENU_STRUCTURE as MODULES, rolesIntersection } from "layouts/Main/components/SidebarRefactored/functions";
import defaultImg from "assets/images/icons/account.svg";
import { isNullOrEmpty } from "common/validators";
import {
  WEEK_DAYS,
  MONTHS,
  SUCCESS,
  ERROR,
  ERROR_TYPE,
  XLSX_EXTENSION,
  FILE_TYPE_EXCEL,
  PASSWORD_VALIDATION_UTILS,
  ROLES,
  FULLDATE_FORMATS,
  DEFAULT_MIN_DECIMAL,
  ROUND,
  PERCENTAGE,
  KEY_PRESS,
  LOCAL_STORAGE_NAMES,
  DOWNLOAD_TYPE,
  EXCEL_PROPS,
  OBJECT_KEYS,
  COUNTRIES,
  INDEX,
  RADIX_PARAMETER,
  MIN_VALUE,
  API_URL_RAILS_V1,
  METHODS,
  CRYPTO_KEY,
  DATE_PARTS,
  LANGUAGES,
  EMBED_VIDEO_BY_FORMATS,
  TOTAL_MONTHS,
  URL_FORMAT,
  GOOGLE_TRANSLATE_URL,
  SLACK_CLIENT_ID,
  DATE_FORMATS,
  ASC,
  DESC,
  SCALE_ORDER,
  COLOR_SCALE,
  HTTP,
  DAYS_TO_FINISH_ONBOARDING,
  MODULES as COMPANY_MODULES,
} from "./constants";
import roleAccess from "./roleAccess";
import {
  isNullOrUndefined, isObject, orderByAsc, orderByDesc,
} from "./helpers";

export const historyPush = (history, path, search, state = null) => {
  history.push({
    pathname: path,
    search,
    state,
  });
};

export const getValidDateFormat = (lang) => DATE_FORMATS[lang];

export const formatDate = (date, format = null, shortFormat = false) => {
  const defaultFormatByLanguage = getValidDateFormat(i18next.language);
  const defaultFormat = shortFormat ? defaultFormatByLanguage?.slashShort : defaultFormatByLanguage?.slash;
  const validFormat = format || defaultFormat || DATE_FORMATS[LANGUAGES.es].slash;
  let momentDate;

  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    momentDate = moment.utc(date).startOf("day");
  } else {
    momentDate = moment.utc(date);
  }

  const validDate = momentDate.format(validFormat);
  return validDate;
};

export const formatDateStartByLanguage = (date, shortFormat = false) => {
  const defaultFormatByLanguage = getValidDateFormat(i18next.language);
  const defaultFormat = shortFormat ? defaultFormatByLanguage?.slashShort : defaultFormatByLanguage?.slash;
  const validFormat = defaultFormat || DATE_FORMATS[LANGUAGES.es].slash;
  const momentDate = moment.utc(date).startOf("day");

  const validDate = momentDate.format(validFormat);
  return validDate;
};

export const formatDateStart = (date) => moment.utc(date).startOf("day");

export const formatDateExcel = (date) => {
  const defaultFormat = getValidDateFormat(i18next.language)?.slash;
  const validFormat = defaultFormat || DATE_FORMATS[LANGUAGES.es].slash;
  return moment.parseZone(date).format(validFormat);
};

export const formatDateAsUTC = (date) => moment(date)
  .utc()
  .format(FULLDATE_FORMATS.dash);

export const formatDateMonthYear = (date) => moment(date).format("MM/YY");

export const formatDateStringShort = (date) => {
  if (date) {
    return moment(date[INDEX.zero]).format("MMM D");
  }
  return "";
};

export const formatPhoneNumber = (phone) => phone.replace(/\D+/g, "");

export const getPercent = (value, hasSymbol = false, round = ROUND.min) => {
  const result = lodashRound(value * PERCENTAGE.max, round);
  return hasSymbol ? `${result}%` : result;
};

export const getNoRoundPercent = (value, hasSymbol = false) => {
  const result = value * PERCENTAGE.max;
  return hasSymbol ? `${result}%` : result;
};

export const getCurrentLanguage = () => i18next.language;

export const getMonthName = (date, t) => t(`common:months.${MONTHS[date.getMonth()]}`);

export const getUtilsFromDate = (date, t) => {
  const validDate = new Date(date);
  const dayName = t(`common:week_days.${WEEK_DAYS[validDate.getDay()]}`);
  const monthName = getMonthName(validDate, t);
  const day = validDate.getUTCDate();
  const monthDay = isEqual(getCurrentLanguage(), LANGUAGES.en) ? `${monthName} ${day}, ` : `${day} ${monthName}`;
  return validDate ? `${dayName}, ${monthDay} ${validDate.getFullYear()}` : "";
};

export const getMonthYear = (date, t) => {
  date = new Date(date);
  const monthName = getMonthName(date, t);
  return date && `${monthName}/${date.getFullYear()}`;
};

export const handleAlertAutohide = (
  errorStatus,
  setAlertAutohide,
  t,
  customSuccessText,
) => {
  const title = errorStatus
    ? "common:common.api_responses.error.title"
    : "common:common.api_responses.success.title";
  const message = errorStatus
    ? `${errorStatus}`
    : customSuccessText || "common:common.api_responses.success.save";

  setAlertAutohide({
    open: true,
    title: t(title),
    message: t(message),
    type: errorStatus ? ERROR : SUCCESS,
  });
};

export const onCloseAlertAutohide = (setAlertAutohide) => () => {
  setAlertAutohide({
    open: false,
    title: "",
    message: "",
    type: ERROR,
  });
};

export const isNotNull = (toValidate) => !isEqual(toValidate, null);

export const isUnauthorized = (errors) => isEqual(errors.toLowerCase(), ERROR_TYPE.unauthorized);

export const setInLocalStorage = (localStorageName, reducer) => {
  const isInLocalStorage = localStorage.getItem(localStorageName);

  if (isNotNull(reducer) && !isInLocalStorage) {
    return localStorage.setItem(localStorageName, JSON.stringify(reducer));
  }
  return null;
};

export const setInLocalStorageAsync = (localStorageName, values) => {
  if (isNotNull(values)) {
    return AsyncLocalStorage.setItem(localStorageName, JSON.stringify(values));
  }
  return null;
};

export const dispatchIfNotLocalStorage = (
  nameLocalStorage,
  action,
  dispatch,
) => {
  const isInLocalStorage = localStorage.getItem(nameLocalStorage);
  if (!isInLocalStorage) {
    dispatch(action);
  }
};

export const getItemFromLocalStorage = (name) => JSON.parse(localStorage.getItem(name)) || [];

export const multipleWorksheet = (allDataToDownload) => {
  const allSheets = {};
  const allSheetsName = [];
  allDataToDownload.forEach((item) => {
    allSheets[item.name] = XLSX.utils.json_to_sheet(item.data);
    allSheetsName.push(item.name);
  });
  return { Sheets: allSheets, SheetNames: allSheetsName };
};

export const mainDownloadExcel = (
  dataToDownload,
  fileName,
  isMultiple = false,
) => {
  const fileExtension = `.${XLSX_EXTENSION}`;
  const wb = isMultiple
    ? multipleWorksheet(dataToDownload)
    : {
      Sheets: { data: XLSX.utils.json_to_sheet(dataToDownload) },
      SheetNames: ["data"],
    };
  const excelBuffer = XLSX.write(wb, {
    bookType: XLSX_EXTENSION,
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: FILE_TYPE_EXCEL });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const validatePassword = (password, passwordConfirmation) => {
  let validPassword = {
    isValid: false,
    typeOfError: "",
  };

  if (
    isEqual(password, passwordConfirmation)
    && gte(password.length, PASSWORD_VALIDATION_UTILS.minLength)
  ) { validPassword = { isValid: true }; } else {
    validPassword = {
      isValid: false,
      typeOfError: !gte(password.length, PASSWORD_VALIDATION_UTILS.minLength)
        ? PASSWORD_VALIDATION_UTILS.length
        : PASSWORD_VALIDATION_UTILS.confirmation,
    };
  }

  return validPassword;
};

export const getUserRoles = (userCookies) => {
  let userRoles = [];
  if (userCookies && !isEmpty(userCookies)) {
    userRoles = userCookies.map((userCookie) => {
      const decrypted = CryptoJS.AES.decrypt(userCookie, CRYPTO_KEY);
      const decryptText = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptText.split("-")[INDEX.two];
    });
  }

  return userRoles;
};

export const isNotCandidate = (from, userCookies) => !includes(from, ROLES.CANDIDATE)
  && !includes(getUserRoles(userCookies), ROLES.CANDIDATE);

// FIXME: this function will be modified, please do not care about it!
export const getAmountFormat = (
  amount,
  decimalLength = DEFAULT_MIN_DECIMAL,
  decimal = ".",
  thousands = ",",
) => {
  decimalLength = Math.abs(decimalLength);
  decimalLength = isNaN(decimalLength) ? DEFAULT_MIN_DECIMAL : decimalLength;

  const negativeSign = amount < 0 ? "-" : "";

  const i = parseFloat(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalLength)),
  ).toString();
  const haveDecimal = i.indexOf(decimal) > -1;
  const len = haveDecimal ? i.substring(0, i.search(/\./)).length : i.length;
  const j = len > 3 ? len % 3 : 0;

  return (
    negativeSign
    + (j ? i.substr(0, j) + thousands : "")
    + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
  );
};

export const getCurrencyCode = (currencyName) => getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies).find(
  (currency) => isEqual(currency.name_with_code, currencyName),
)?.code;

export const getCurrencyFormat = (value, currency, currencies) => {
  if (currency && currencies) {
    const exchange = find(currencies, { name_with_code: currency })?.value;
    value = exchange ? divide(value, exchange) : "";
  }
  return value;
};

export const hasEmptyInput = (control, objKey, validateAllFields) => includes(validateAllFields(control.getValues()), objKey);

export const hasAllValidatedFields = (arrayWithKeys) => isUndefined(
  Object.keys(arrayWithKeys).find((key) => isEqual(arrayWithKeys[key], true)),
);

export const preventEnter = (e) => isEqual(e.key, KEY_PRESS.enter) && e.preventDefault();

export const getEmployeeCompanyId = (user) => user?.company?.id || user?.person?.employee?.company_id;

export const getUserId = (user) => user?.id;

export const getEmployeeId = (user) => user?.employee?.id;

export const getEmployeeCompanySlug = (user) => user?.company?.slug;

export const getEmployeeCompanyName = (user) => user?.company?.name;

export const getAutocompleteAttr = (newValue, nameOfAttr) => (isNull(newValue) || isString(newValue) ? newValue : newValue[nameOfAttr]);

export const getDivisionAndAreaType = () => {
  const organizationUnitsTypes = getItemFromLocalStorage(
    LOCAL_STORAGE_NAMES.orgUnitsTypes,
  );

  return {
    division: organizationUnitsTypes.find((type) => type.level === 1),
    area: organizationUnitsTypes.find((type) => type.level === 2),
    subarea: organizationUnitsTypes.find((type) => type.level === 3),
  };
};

export const getDivisions = (orgUnits) => {
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeDivisionId = organizationUnitsTypes?.division?.id;
  return !isEmpty(orgUnits)
    ? orgUnits.filter(
      (orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeDivisionId,
    )
    : [];
};

export const getAreas = (organizationUnits, divisionSelected) => {
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeAreaId = organizationUnitsTypes?.area?.id;
  const allOrgUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);
  const areasSelected = allOrgUnits.filter(
    (orgUnit) => orgUnit.parent_id === divisionSelected
      && orgUnit.organization_unit_type_id === orgUnitTypeAreaId,
  );
  return areasSelected;
};

export const getElementNewValue = (value, name) => (!isNull(value) ? name : "");

export const roles = localStorage.user
  ? JSON.parse(localStorage.user)?.roles_name
  : null;

export const isAdminNala = (userCookies) => includes(getUserRoles(userCookies), ROLES.ADMIN_NALA);

// note: idk why isAdmin function includes all those options, should be ONLY admin, please do a refactor of this
export const isValidAdmin = (userCookies) => some(
  getUserRoles(userCookies),
  (rol) => isEqual(rol, ROLES.ADMIN)
      || isEqual(rol, ROLES.ADMIN_NALA)
      || isEqual(rol, ROLES.ADMIN_COLOMBIA),
);
export const isAdmin = (userCookies) => some(
  getUserRoles(userCookies),
  (rol) => isEqual(rol, ROLES.ADMIN)
      || isEqual(rol, ROLES.ADMIN_NALA)
      || isEqual(rol, ROLES.TALENT_MANAGER)
      || isEqual(rol, ROLES.ADMIN_COLOMBIA),
);

export const isMainAdmin = (userCookies) => some(getUserRoles(userCookies), (rol) => isEqual(rol, ROLES.ADMIN));

export const isCandidate = (userCookies) => !isEmpty(
  getUserRoles(userCookies)?.filter((role) => isEqual(role, ROLES.CANDIDATE)),
);

export const isCollaborator = (userCookies) => !isEmpty(
  getUserRoles(userCookies)?.filter((role) => isEqual(role, ROLES.COLLABORATOR)),
);

export const getDownloadTypes = (t) => [
  { value: DOWNLOAD_TYPE.none, label: t("performance:dashboard.download") },
  { value: DOWNLOAD_TYPE.excel, label: t("performance:dashboard.excel") },
  { value: DOWNLOAD_TYPE.pdf, label: t("performance:dashboard.download_pdf") },
];

export const getPropertyByLocation = (location, propName) => location[propName];

// Receives an array and the name of the key to be listed
// Returns an array
export const getStringList = (data, name) => data?.map((item) => item[name]);

const excelStyleByRequiredColumn = (cell, isListType) => {
  const border = EXCEL_PROPS.style.border.thin;
  cell.fill = {
    type: EXCEL_PROPS.type.pattern,
    pattern: EXCEL_PROPS.pattern.solid,
    fgColor: { argb: EXCEL_PROPS.style.colors.fgColorRequired },
    bgColor: { argb: EXCEL_PROPS.style.colors.bgColorRequired },
  };
  cell.border = {
    top: { style: border },
    left: { style: border },
    bottom: { style: border },
    right: { style: border },
  };
  cell.font = {
    bold: isListType,
    name: EXCEL_PROPS.font,
  };
};

export const getLetters = (value) => value.replace(/\d/g, "");

export const getExcelFormula = (columName, dataList) => `=${OBJECT_KEYS.listings}!$${columName}$${EXCEL_PROPS.position.one}:$${columName}${dataList.length}`;

export const mainDownloadExcelCustom = (dataToDownload, fileName, t) => {
  const header = dataToDownload.map((item) => item.name);
  const fileExtension = `.${XLSX_EXTENSION}`;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  // Add worksheet hidden for lists
  const worksheetList = workbook.addWorksheet(OBJECT_KEYS.listings);
  worksheetList.state = EXCEL_PROPS.state.hidden;
  // Add Header Row
  worksheet.addRow(header);
  // Custom cells by column
  worksheet.columns.forEach((column, index) => {
    const columnData = dataToDownload[index];
    const dataList = getStringList(columnData.list, columnData.keyList);
    let maxLength = EXCEL_PROPS.style.minLength;
    column[EXCEL_PROPS.eachCell]({ includeEmpty: true }, (cell) => {
      const columnLength = cell.value
        ? cell.value.toString().length
        : EXCEL_PROPS.style.minWidth;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
      // Styles only if required column
      columnData.isRequired
        && excelStyleByRequiredColumn(cell, !isEmpty(dataList));
      // Only list type
      if (!isEmpty(dataList)) {
        const columName = getLetters(cell._address);
        // Add list to worksheet hidden
        worksheetList.getColumn(index + 1).values = dataList;
        worksheet.dataValidations.add(
          `${cell._address}:${cell._address}${EXCEL_PROPS.plusCell}`,
          {
            type: EXCEL_PROPS.type.list,
            allowBlank: false,
            formulae: [getExcelFormula(columName, dataList)],
            showErrorMessage: true,
            errorStyle: EXCEL_PROPS.errorStyle.error,
            error: t("common:common.excel.selectError"),
          },
        );
      }
    });
    column.width = maxLength < EXCEL_PROPS.style.minWidth
      ? EXCEL_PROPS.style.minWidth
      : maxLength + EXCEL_PROPS.style.maxLength;
  });
  workbook.xlsx.writeBuffer().then((data) => {
    const dataToSave = new Blob([data], {
      type: XLSX_EXTENSION,
    });
    FileSaver.saveAs(dataToSave, `${fileName}${fileExtension}`);
  });
};

export const getAllExceptSelected = (allItems, findByKey) => allItems?.filter(
  (item) => item.id !== findByKey && item.parent_id !== findByKey,
);

export const getObjectStructure = (module, data) => {
  let dataStructure;
  switch (module) {
  case UNITS:
    dataStructure = { organization_unit: data };
    break;
  case CONTRACT_TYPES:
    dataStructure = { type_of_contract: data };
    break;
  case CITIES:
    dataStructure = { city: data };
    break;
  case POSITIONS:
    dataStructure = { position: data };
    break;
  case ADDITIONAL_FIELDS:
    dataStructure = { dynamic_attribute: data };
    break;
  default:
    break;
  }
  return dataStructure;
};

export const isColombianAdmin = (userCookies) => !isEmpty(
  getUserRoles(userCookies)?.filter((role) => isEqual(role, ROLES.ADMIN_COLOMBIA)),
);

export const isColombianEmployee = (country) => country === COUNTRIES.colombia;

export const handleAlertDetail = (reducer, setAlert, t) => {
  if (reducer.error) {
    setAlert({
      open: true,
      title: t("common:common.api_responses.error.title"),
      message:
        reducer.error.data.errors_messages?.join("\n")
        || reducer.error.data.detail,
      type: ERROR,
    });
  } else {
    setAlert({
      open: true,
      title: t("common:common.api_responses.success.title"),
      message: t("common:common.api_responses.success.save"),
      type: SUCCESS,
    });
  }
};

export const getFormattedItems = (localStorageName, list) => {
  const localStorageElements = localStorageName
    ? getItemFromLocalStorage(localStorageName)
    : list;
  const formattedElements = localStorageElements?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return formattedElements;
};

export const isDateGreaterThanToday = (value) => value > moment();

// compare if value is greater than moment + 1 day
export const isDateGreaterThanTomorrow = (value) => formatDate(value, "YYYY-MM-DD") > formatDate(moment().add(1, "days"), "YYYY-MM-DD");

export const capitalizeFirstLetter = (string) => string[INDEX.zero].toUpperCase() + string.slice(INDEX.one);

export const getPositionId = (positionName) => {
  const allPositions = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions);
  return (
    positionName && allPositions.find((item) => item.name === positionName)
  );
};

export const getFirstThreeLetter = (word) => word.slice(0, 3).toUpperCase();

export const getListMonthName = (monthNum, t) => {
  const monthIndex = monthNum - 1;
  const monthToReturn = MONTHS[monthIndex];
  return getFirstThreeLetter(t(`common:months.${monthToReturn}`));
};

export const getLabelCharts = (reducer, t) => {
  const label = reducer?.map((item) => getListMonthName(item?.month, t));
  return label;
};

export const getDataCharts = (reducer, dataToExtract) => reducer?.map((item) => item[dataToExtract]);

// Get acknowledgement Icon given an ID
export const getAcknowledgementIcon = (id, list) => list?.find((item) => item.id === id);

export const getNormalizedString = (string) => string?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();

export const truncateString = (string, maxLength = 25) => (string?.length > maxLength ? `${string.slice(0, maxLength)}...` : string);

export const getFormattedTags = (options, nameAttr, hasValidation = false) => {
  const formattedElements = options?.map((item) => {
    const formattedItem = {
      id: item.id,
      value: item[nameAttr],
      label: item[nameAttr],
    };

    if (item?.country_id) {
      formattedItem.country = getCountry(item.country_id)?.name;
    }
    if (hasValidation) {
      formattedItem.email = item.email;
      formattedItem.isActive = item.is_active;
    }

    return formattedItem;
  });
  return formattedElements;
};

export const getOptionListId = (tags) => {
  let elements = [];
  if (tags) {
    elements = tags?.map((tag) => tag.id);
  }

  return elements;
};

export const getOptionsFormat = (options) => options?.map((item) => {
  const newItem = {
    value: item.id,
    label: item.name || item.description || item.full_name, // note: add here all posible ways the read the label for an autocomplete
  };
  return newItem;
});

export const isManager = (userCookies) => some(getUserRoles(userCookies), (rol) => isEqual(rol, ROLES.MANAGER));

export const isOnlyCollaborator = (userCookies) => isEqual(getUserRoles(userCookies).length, INDEX.one)
  && isCollaborator(userCookies);

export const isAdminOrManager = (userCookies) => isAdmin(userCookies) || isManager(userCookies);

export const getCompanyCountriesList = (countriesList, collaborators) => {
  const companyCountriesList = countriesList.filter((country) => collaborators?.find(
    (collaborator) => collaborator.country_name === country.name,
  ));
  return companyCountriesList;
};

export const getMaxYLabel = (dataList = []) => {
  const maxData = Math.floor(Math.max(...dataList) * 1.25);
  return maxData;
};

export const renderCustomLabelTicks = (props) => {
  const {
    payload, x, y, textAnchor, stroke, radius,
  } = props;
  return (
    <g>
      <text
        radius={ radius }
        stroke={ stroke }
        x={ x }
        y={ y }
        textAnchor={ textAnchor }
        fill={ charts.colors.lightText }
        fontSize={ charts.labels.standar }
      >
        {`${payload.value}%`}
      </text>
    </g>
  );
};

export const enableComponent = (companySlug, companyList) => !companyList.includes(companySlug);

export const isCurrentCompanySlug = (user, companySlug) => isEqual(user?.company?.slug, companySlug);

export const isCurrentRole = (user, role) => user?.userCookies
  && getUserRoles(user.userCookies)?.some((roleItem) => isEqual(roleItem, role));

// note:
// The radix parameter is used to specify which numeral system to be used, for example, a radix of 16 (hexadecimal) indicates that the number in the string should be parsed from a hexadecimal number to a decimal number.
// If the radix parameter is omitted, JavaScript assumes the following:
// If the string begins with any other value, the radix is 10 (decimal)
export const getParamEmployeeId = (user, params) => parseInt(params?.collaborator, RADIX_PARAMETER)
  || parseInt(params?.candidate, RADIX_PARAMETER)
  || user?.employee?.id;

export const getMainRoute = () => {
  const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
  // TODO: show first menu option that is active
  return isCurrentRole(user, ROLES.CANDIDATE) ? "preboarding" : "home";
};

const getCompaniesValidation = (data, companySlug) => data?.companiesValidation?.find(
  (validation) => isEqual(validation.company, companySlug),
);

export const getValidationRoutes = (pathname) => {
  try {
    const allowedRoutes = getItemFromLocalStorage(
      LOCAL_STORAGE_NAMES.userPathOptions,
    );
    const user = getItemFromLocalStorage(
      LOCAL_STORAGE_NAMES.user,
    );
    const normalizedPath = pathname?.includes("profile") ? "/profile" : pathname;
    const companySlug = user.company.slug;
    const rolesName = user.roles.map((rol) => rol.name);
    let isValidToShow = false;
    MODULES.forEach((item) => {
      if (item?.subMenu) {
        item.subMenu.forEach((subMenu) => {
          if (subMenu.href === normalizedPath) {
            const companiesValidation = getCompaniesValidation(subMenu, companySlug);
            isValidToShow = rolesIntersection(companiesValidation || subMenu, rolesName);
          }
        });
      } else if (item.href === normalizedPath) {
        const companiesValidation = getCompaniesValidation(item, companySlug);
        isValidToShow = rolesIntersection(companiesValidation || item, rolesName);
      }
    });
    // if you already have a module visible but the permissions are changed by role,
    // don't allow it to access the path
    const isAllowedRoute = allowedRoutes?.includes(normalizedPath) && isValidToShow;

    return isAllowedRoute;
  } catch (error) {
    return false;
  }
};

export const getSkillSection = (score, scale) => scale.find((item) => score >= item.bottom && score <= item.top)?.result;

export const getAverageScore = (results) => results.reduce((total, next) => total + next.score, INDEX.zero)
    / results.length || results.length;

export const getAverageResult = (results) => {
  const notNullResults = results.filter((item) => !isNull(item));
  if (notNullResults.length === 0) {
    return [];
  }
  const sortedResults = notNullResults.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
  const averageResults = sortedResults.map(
    (result) => result?.average_result?.toFixed(DEFAULT_MIN_DECIMAL),
  );
  return averageResults;
};

export const getLastProcessId = (processes) => processes.find((process) => process.average_result !== null)?.id;

export const calculateLossRisk = (list, collaboratorId) => {
  const attritionLossRisk = list?.map((item) => {
    let verticeValues = MIN_VALUE;
    let calculatedAttrition = MIN_VALUE;
    if (item.employee_id === collaboratorId) {
      verticeValues = item.attrition_axis_values
        .filter((vertice) => vertice.value !== null)
        .map((vertice) => {
          const sumatory = vertice.value * vertice.weighing;
          return { sumatory, weight: vertice.weighing };
        });

      const sumatoryOfVertices = verticeValues.reduce(
        (currentValue, vertice) => currentValue + vertice.sumatory,
        MIN_VALUE,
      );
      const weightOfVertices = verticeValues.reduce(
        (currentValue, vertice) => currentValue + vertice.weight,
        MIN_VALUE,
      );
      calculatedAttrition = 1 - sumatoryOfVertices / weightOfVertices;
    }
    return calculatedAttrition;
  });
  return attritionLossRisk;
};

export const hexToRgbA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

export const isValidRole = (userCookies, role) => getUserRoles(userCookies)?.filter((item) => item === role).length !== 0;

export const getValidDate = () => `${moment(new Date(), "MM-DD-YYYY HH:mm:ss")
  .utc()
  .format("YYYY-MM-DD HH:mm:ss")} GMT`;

export const getCryptoConfig = (validDate) => {
  const companyName = getEmployeeCompanyName(
    getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user),
  ); // FIXME: remove this after TOKEN implementation
  const validString = `${companyName}${validDate}`;
  const hash = crypto
    .createHmac("sha1", CRYPTO_KEY)
    .update(validString)
    .digest("base64");
  return hash;
};

export const getAxiosDeleteConfig = (mainUrl, id, data) => {
  const deleteConfig = {
    method: METHODS.delete,
    url: `${API_URL_RAILS_V1}${mainUrl}/${id}`,
  };

  if (data) {
    deleteConfig.data = data;
  }
  return deleteConfig;
};

export const getActiveList = (list) => list?.filter((collaborator) => collaborator?.is_active);

export const formatDateUTC = (date) => moment(date).utc();

export const excelHeader = (
  key,
  name,
  isRequired,
  format,
  // eslint-disable-next-line
  list = [],
  keyList,
) => ({
  key,
  name,
  isRequired,
  format,
  list,
  keyList,
});

export const sumDaysFromDate = (date, followUpDays) => {
  const formattedDate = formatDateUTC(date);
  const newDate = formattedDate.add(followUpDays, "d");
  return newDate;
};

export const getDaysFromToday = (startingDate) => {
  const today = formatDateUTC(new Date());
  const startingDateFormatted = formatDateUTC(startingDate);
  return today.diff(startingDateFormatted, DATE_PARTS.days);
};

export const hasPreviewImage = (collaborator) => collaborator.profile_img_url_thumb && collaborator.profile_img_url;

export const mergeListsOfObjects = (firstList, secondList) => {
  const ids = new Set(firstList.map((item) => item.id));
  return [...firstList, ...secondList.filter((item) => !ids.has(item.id))];
};

export const removeById = (list, id) => list.filter((item) => item.id !== id);

export const getSelectCollaboratorOptions = (collaborators) => collaborators?.map((item) => ({
  value: item.id,
  label: item.full_name,
  avatar: item.profile_img_url,
}));

export const getDefaultFormatOption = (collaborator) => ({
  value: collaborator.id,
  label: collaborator.full_name,
  avatar: collaborator.profile_img_url,
});

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => isEqual(object[key], value));

export const getFormatMulticompany = (data) => {
  const index = data.companies?.findIndex((e) => isEqual(e.id, data.company_id));
  data.companies[index][OBJECT_KEYS.isMain] = true;
  return {
    user: {
      ...data,
      company: data.companies[index],
    },
  };
};

export const isMainCompany = (user) => !isUndefined(user?.company?.is_main) || isUndefined(user?.companies);

export const getMaxFollowUpDays = (followUpProcess) => !isEmpty(followUpProcess) && followUpProcess[INDEX.zero]?.max_follow_up_days;

export const normalizeText = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const toSnakeCase = (name) => name
  .replace(/\W+/g, " ")
  .split(/ |\B(?=[A-Z])/)
  .map((word) => word.toLowerCase())
  .join("_");

export const convertSnakeCase = (name) => toSnakeCase(normalizeText(name).toLowerCase());

// Check if a user has valid scopes based on employee scopes and role
export const isValidScope = (user, employeeScopes = [], ignoredRoles = [], scopeName = OBJECT_KEYS.id) => {
  const userRoles = user?.roles_with_scope;

  // If user does not have any role_with_scopes, return true
  if (!userRoles || userRoles.length === 0) {
    return true;
  }

  const ignoredRolesByDefault = [ROLES.COLLABORATOR, ROLES.MANAGER];
  // Combine default ignored roles with additional ignored roles
  const allIgnoredRoles = [...ignoredRolesByDefault, ...ignoredRoles];

  const filteredRoles = userRoles.filter(
    (role) => !allIgnoredRoles.includes(role.name),
  );

  const hasValidScope = filteredRoles.some((role) => {
    // Get the keys of the scopes object that start with "managed"
    const hasValidScopes = Object.keys(employeeScopes).every((scope) => {
      const employeeScope = employeeScopes[scope];
      const validScopes = role[`managed_${scope}`]; // Access the validScopes of the role

      // If required scope is not defined or is empty
      if (!employeeScope || employeeScope.length === 0) {
        return !(validScopes && validScopes.length > 0);
      }

      // Check if every employee scope has a corresponding valid scope
      return validScopes && validScopes.length === 0
        ? true
        : validScopes.some((validScope) => employeeScope.includes(validScope[scopeName]));
    });

    // Return the result whether user has valid scopes or not
    return hasValidScopes;
  });

  return hasValidScope;
};

export const isMulticompanyUser = () => isEqual(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.isMulticompany), true);

export const replacePath = (history, route) => {
  history.replace(`${route}${history?.location?.search}`);
};

export const urlToEmbed = (url) => {
  let embedUrl = url;
  EMBED_VIDEO_BY_FORMATS.forEach((item) => {
    const match = url?.match(item.format);
    if (match) {
      embedUrl = item.result(match);
    }
  });
  return embedUrl;
};

export const getScaleByScore = (score, scale) => scale.find(
  (item) => score >= item.bottom && score <= item.top,
);

export const toMonthName = (monthNumber, locate) => {
  const date = new Date();
  date.setDate(INDEX.one);
  date.setMonth(monthNumber);

  return date.toLocaleString(locate, {
    month: "short",
  });
};

export const moduleByMonths = (value) => ((value % TOTAL_MONTHS) + TOTAL_MONTHS) % TOTAL_MONTHS;

export const isValidVacationAdmin = (userCookies) => isValidAdmin(userCookies) || isValidRole(userCookies, ROLES.VACATION_ADMIN);

export const cleanQuery = (query) => Object.entries(query).reduce(
  (item, [key, value]) => (isNullOrUndefined(value) ? item : ((item[key] = value), item)),
  {},
);

export const getValueByProp = (value, prop) => (isObject(value) ? value[prop] : value);

export const getTranslatedURL = (url, currentLang, baseLang = LANGUAGES.en) => {
  if (URL_FORMAT.video.find((format) => url.match(format))) {
    return url;
  }
  return isEqual(baseLang, currentLang)
    ? url
    : `${GOOGLE_TRANSLATE_URL}?sl=${baseLang}&tl=${currentLang}&u=${url}`;
};

export const getParseValue = (value) => (value ? parse(value) : "");

export const getSlackUrlByCompany = (companyId) => `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=chat:write,chat:write.customize,chat:write.public,conversations.connect:write,im:write,incoming-webhook,users:read,users:read.email,links:read,links:write&state=${companyId}`;

export const getProcessNameById = (list, id, language) => {
  const process = list.find((item) => isEqual(item.id, id));
  return process ? process[`name_${language}`] : "";
};

export const formatDateToSubmit = (date, format = DATE_FORMATS.es.dash) => {
  const defaultFormat = getValidDateFormat(i18next.language)?.slash;
  const validDate = moment(date, defaultFormat, true).utc().format(format);
  return validDate;
};

export const isValidDate = (date) => {
  const lastFormat = getValidDateFormat(i18next.language).slash || getValidDateFormat(i18next.language).dash;
  return moment(date, lastFormat, true).isValid();
};

export const getSortingOrder = (column, direction) => {
  const directionOrder = direction ? ASC : DESC;
  return `${column} ${directionOrder}`;
};

// isAscOrder - use to avoid the rule whenever it tests more than one person at a time
export const getScaleIndicesOrder = (indices, isAscOrder = false) => {
  const isValidForAscOrder = indices[INDEX.zero].answer_es.length <= SCALE_ORDER.min;
  return isValidForAscOrder || isAscOrder ? orderByAsc(indices, OBJECT_KEYS.value) : orderByDesc(indices, OBJECT_KEYS.value);
};

export const getSearchParams = (search) => Object.fromEntries(new URLSearchParams(search));

export const getUsername = () => getItemFromLocalStorage(LOCAL_STORAGE_NAMES.username);

// Function to find the index of the axis limit that a given position belongs to
export const findAxisIndex = (position, axisLimits) => {
  for (let i = 0; i < axisLimits.length - 1; i += 1) {
    if (position >= axisLimits[i] && position <= axisLimits[i + 1]) {
      return i;
    }
  }
  return -1;
};

export const getAxisLimits = (scaleIndices) => {
  const xValues = [];
  const yValues = [];

  // Iterate through each object in the scaleIndices array
  scaleIndices.forEach((scaleIndex) => {
    // Push the x_axis_bottom and x_axis_top values to the xValues array
    xValues.push(getPercent(scaleIndex.x_axis_bottom));
    xValues.push(getPercent(scaleIndex.x_axis_top));

    // Push the y_axis_bottom and y_axis_top values to the yValues array
    yValues.push(getPercent(scaleIndex.y_axis_bottom));
    yValues.push(getPercent(scaleIndex.y_axis_top));
  });

  // Remove duplicate values from the xValues and yValues arrays
  const uniqueXValues = [...new Set(xValues)];
  const uniqueYValues = [...new Set(yValues)];

  // Sort the values in ascending order
  uniqueXValues.sort((a, b) => a - b);
  uniqueYValues.sort((a, b) => a - b);

  // Return an object with the x and y values as arrays
  return { x: uniqueXValues, y: uniqueYValues };
};
export const getAvatarPromise = (employee) => {
  const imageKey = employee?.profile_img_key;
  const externalImage = employee?.external_img_url;
  const defaultImgPromise = typeof defaultImg === "string"
    ? Promise.resolve(defaultImg)
    : getAvatarImage(defaultImg);
  return getAvatarImage(imageKey).catch(() => externalImage || defaultImgPromise);
};

export const getScaleWithColors = (scale) => orderByDesc(scale, OBJECT_KEYS.top)
  .map((item, index) => ({
    ...item,
    color: COLOR_SCALE[index],
  }));

export const getRange = (scale) => `${getPercent(scale.bottom, true)} - ${getPercent(scale.top, true)}`;

/**
 * Finds the index of the nearest null element in an array of arrays.
 * @param {Array} arrayOfArrays - An array of arrays to search for null elements.
 * @return {number} The index of the nearest null element, or null if no null elements were found.
 */
export const findNearestNullIndex = (arrayOfArrays) => {
  let nearestNullIndex = null;

  // Iterate over the keys of the arrayOfArrays object
  Object.keys(arrayOfArrays).forEach((key) => {
    const currentArray = arrayOfArrays[key];
    const currentNullIndex = currentArray.indexOf(null);

    // If a null element is found in the current array
    if (currentNullIndex !== -1) {
      // If this is the first null element found, or if it's closer than the previous one found
      if (nearestNullIndex === null || Math.abs(currentNullIndex) < Math.abs(nearestNullIndex)) {
        nearestNullIndex = currentNullIndex;
      }
    }
  });

  return nearestNullIndex;
};

export const switchOptions = (
  label,
  value,
  content = null,
  customTitle = true,
  isTitleBelow = false,
  selectedBackgroundColor = palette.white,
  selectedFontColor = palette.text.link,
) => (
  {
    label, value, content, customTitle, selectedBackgroundColor, selectedFontColor, isTitleBelow,
  }
);

/**
 * Checks if the user has access to a specific module.
 * @param {object} userCookies - User cookies containing the necessary information.
 * @param {string} moduleName - Name of the module to check access to.
 * @returns {boolean} - Indicates if the user has access to the specified module.
 */
export const canAccessModule = (userCookies, moduleName) => {
  const userRoles = getUserRoles(userCookies);
  const moduleRoles = roleAccess[moduleName] || [];

  return userRoles.some((role) => moduleRoles.includes(role));
};

export const getEmployeeImage = (img) => (img && !isNullOrUndefined(img) ? img : defaultImg);

/**
 * Navigates to a specified path with optional query parameters.
 * @param {object} history - The history object provided by React Router.
 * @param {string} path - The path to navigate to.
 * @param {array} params - An array of objects representing query parameters.
 */
export const navigateToPath = (history, path = "", params = []) => {
  let query = "";

  if (params.length > 0) {
    // Construct the query string from the params array
    const queryParams = params.map(({ paramName, value }) => `${paramName}=${value}`);
    query = `?${queryParams.join("&")}`;
  }

  // Construct the full path including the query string
  const fullPath = `${path}${query}`;

  // Use the history object to navigate to the full path
  history.push(fullPath);
};

/**
 * Extracts and converts the ID parameter from a search string.
 * @param {string} search - The search string containing query parameters.
 * @returns {number|null} - The parsed ID or null if not found or invalid.
 */
export const getIdParam = (search) => {
  const searchParams = new URLSearchParams(search);
  const processId = searchParams.get(OBJECT_KEYS.id);
  // convert to number if is not null or empty
  return !isNullOrEmpty(processId) ? parseInt(processId, 10) : null;
};

/**
 * Filter data based on a search text.
 * @param {Array} data - The data to be filtered.
 * @param {string} searchText - The text to search for.
 * @returns {Array} - The filtered data.
 */
export const searchData = (data, searchText) => {
  // If there is no search text, return the unfiltered data
  if (!searchText) return data;

  const filteredData = data.filter((row) => {
    const values = Object.values(row);

    // Check if any of the row values contains the search text and is not a link
    const isFound = values?.some((value) => !value?.toString().startsWith(HTTP)
      && getNormalizedString(value?.toString())?.toLowerCase()
        .includes(getNormalizedString(searchText).toLowerCase()));

    return isFound ? row : null;
  });

  return filteredData;
};

/**
 * Returns a random color from the charts colors array.
 * @param {number} index - The index of the color to return.
 * @returns {string} - The color.
 */
export const getRandomColor = (index) => charts.colors.spiderChart[index];

/**
 * Export data to Excel.
 * @param {Array} dataToDownload - The data to be exported.
 * @param {string} fileName - The name of the file to be exported.
 * @param {boolean} isMultiple - Indicates if the data contains multiple sheets.
 * @param {boolean} skipHeader - Indicates if the header should be skipped.
 * @returns {void}
 */
export const customDownloadExcel = (
  dataToDownload,
  fileName,
  isMultiple = false,
  skipHeader = false,
) => {
  const fileExtension = `.${XLSX_EXTENSION}`;
  const allSheets = {};
  const allSheetsName = [];
  const preparedData = {};

  if (isMultiple) {
    dataToDownload.forEach((item) => {
      allSheets[item.name] = XLSX.utils.json_to_sheet(item.data, {
        skipHeader,
      });
      allSheetsName.push(item.name);
    });

    preparedData.Sheets = allSheets;
    preparedData.SheetNames = allSheetsName;
  } else {
    preparedData.Sheets = { data: XLSX.utils.json_to_sheet(dataToDownload, { skipHeader }) };
    preparedData.SheetNames = ["data"];
  }

  const excelBuffer = XLSX.write(preparedData, {
    bookType: XLSX_EXTENSION,
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: FILE_TYPE_EXCEL });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const isNewHire = (startingDate) => {
  const currentDate = new Date();
  const startDate = new Date(startingDate);

  const daysSinceStartDate = differenceInDays(currentDate, startDate);
  const isLessThanOnboardingDays = daysSinceStartDate < DAYS_TO_FINISH_ONBOARDING;

  return isLessThanOnboardingDays;
};

export const generateRandomId = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(
    { length },
    () => characters.charAt(Math.floor(Math.random() * characters.length)),
  ).join("");
};

export const createResultToSkillLevelMap = (resultScale) => {
  const map = {};
  resultScale?.result_scale_indices.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key.startsWith("result_") && item[key]) {
        map[item[key]] = item.nine_box_result;
      }
    });
  });
  return map;
};

export const getUTCYearFromDate = (dateString) => {
  const date = new Date(`${dateString}T00:00:00Z`);
  return date.getUTCFullYear();
};

export const extractTextWithoutNumber = (text) => {
  const pattern = /^\d+\.\s*/;
  return text.replace(pattern, "");
};

export const isValidToKeyPositionModule = (user) => user?.company?.visible_items.some(
  (item) => (item.slug === COMPANY_MODULES.keyPositions
    || item.slug === COMPANY_MODULES.keyPositionsLast)
      && item.state === "active",
);

export const isSuccessionAdmin = (
  userCookies,
) => some(getUserRoles(userCookies), (rol) => rol === ROLES.SUCCESSIONS_ADMIN);

export const isAdminOrSuccessionAdmin = (user) => (isValidAdmin(user?.userCookies)
|| isSuccessionAdmin(user?.userCookies)) && isValidToKeyPositionModule(user);

export const isValidToKeyPositionAction = (user) => (isSuccessionAdmin(user?.userCookies)
|| isAdminNala(user?.userCookies)) && isValidToKeyPositionModule(user);

export const compareProps = (obj1, obj2, properties) => properties.every(
  (prop) => obj1[prop] === obj2[prop],
);

export const searchDataByName = (data, searchText, searchBy = "name") => {
  if (!searchText) return data;

  const normalizedSearchText = getNormalizedString(searchText).toLowerCase();

  const filteredData = data.filter((row) => {
    const nameField = row[searchBy] || "";
    const normalizedValue = getNormalizedString(nameField).toLowerCase();
    return normalizedValue.includes(normalizedSearchText);
  });

  return filteredData;
};

export const formatDateMovementToSubmit = (date) => {
  const format = DATE_FORMATS.es.slashFullTime;
  const defaultFormat = getValidDateFormat(i18next.language)?.slash || DATE_FORMATS.es.slash;

  const parsedDate = moment(date, defaultFormat, true).seconds(0);
  const currentTimeInUTC = moment().utc().seconds(0).format("HH:mm:ss");
  const combinedDateTime = `${parsedDate.format("YYYY-MM-DD")}T${currentTimeInUTC}Z`;
  const validDate = moment.utc(combinedDateTime).format(format);
  return validDate;
};

export const getCitiesByCountry = (countrySelected, cities = []) => {
  const citiesSelected = cities.filter((city) => city.country_id === countrySelected);
  return citiesSelected;
};

export const findDataInScale = (scale, input, isResult = true) => {
  if (!scale) {
    return null;
  }

  const sortedScale = scale.slice().sort((a, b) => a.top - b.top);

  const index = sortedScale.findIndex((item) => {
    if (isResult) {
      return item.result === input
        || item.result_es === input
        || item.result_en === input
        || item.result_pt === input;
    }
    return input >= item.bottom && input <= item.top;
  });

  if (index === -1) {
    return null;
  }

  const scaleLength = sortedScale.length;
  const position = index + 1;
  const resultObject = {
    ...sortedScale[index],
    position,
    max: scaleLength,
  };

  return resultObject;
};

// Function to truncate name to a maximum of 12 characters
export const truncateName = (name, maxLength = 12) => (name.length > maxLength ? `${name.substring(0, maxLength)}...` : name);
